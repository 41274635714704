
// Custom Time validation
window.Parsley
    .addValidator('timeFormat', {
        requirementType: 'string',
        validateString: function(value, requirement) {
            if (!requirement) {
                return true;
            }

            var regex = /^(0?[0-9]|1[0-9]|2[0-3])(:[0-5][0-9])$/;
            var matches = value.match(regex);

            if (matches !== null && matches.length > 0) {
                return true;
            }
            return false;
        },
        messages: {
            en: 'The time must be entered in a 24 hour format HH:MM'
        }
    });

